import React, {
  HTMLAttributes,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useForm } from 'react-hook-form';
import { BuscaProduto } from '~/components/NovosInputs';
import { useModalDeCorrecoes } from '../../ModalDeCorrecoesContext';
import { useValidadorXML } from '~/pages/ValidadorXML/ValidadorContext';

import { SelectType } from '~/components/NovosInputs/BuscaProduto/protocols';
import { Container } from './styles';
import { moneyFormat } from '~/utils/functions';

/**
 * @param SubstituirProduto Aqui é efetuada a substituição do Item por outro existente no ERP
 */

export const SubstituirProduto: React.FC = () => {
  const [showData, setShowData] = useState<boolean>(false);
  const { novoProduto, setNovoProduto, modalTab } = useModalDeCorrecoes();

  const { dadosDaCapa } = useValidadorXML();

  const buscaProdutoContainerRef = useRef<HTMLDivElement | null>(null);

  const { register, control, formState } = useForm({
    reValidateMode: 'onBlur',
  });

  const handleProductSelected = useCallback(
    (selected: any) => {
      setNovoProduto(selected[0]);
      setShowData(true);
    },
    [setNovoProduto],
  );

  useEffect(() => {
    if (modalTab === 'substituirProduto' && buscaProdutoContainerRef.current) {
      const { current } = buscaProdutoContainerRef;
      const inputBuscaProduto: HTMLInputElement | null = current.querySelector(
        '[name="busca_produto"]',
      );

      if (inputBuscaProduto) {
        inputBuscaProduto.focus();
        inputBuscaProduto.click();
      }
    }
  }, [modalTab]);

  return (
    <Container className="container">
      <div className="row">
        <div className="col-md-12" ref={buscaProdutoContainerRef}>
          <BuscaProduto
            label="Buscar Produto"
            register={register}
            control={control}
            name="busca_produto"
            placeholder="Digite para pesquisar"
            isError={!!formState.errors.busca_produto}
            buscaNasLojas={Number(dadosDaCapa.cod_loja)}
            customOptions={{
              buscarPor: ['Produto'],
            }}
            getProduct={(selected: SelectType) => {
              handleProductSelected(selected);
            }}
          />
        </div>
        {showData && novoProduto && (
          <div className="col-sm-12 mt-4">
            <table className="table">
              <thead>
                <tr>
                  <th>Cód. GTIN</th>
                  <th>Cód. PLU</th>
                  <th>Descrição</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{novoProduto.cod_gtin_principal}</td>
                  <td>{novoProduto.cod_produto}</td>
                  <td>{novoProduto.des_produto}</td>
                </tr>
              </tbody>
            </table>
            <table className="table">
              <thead>
                <tr>
                  <th>Unidade Compra / Venda</th>
                  <th>Cód. NCM</th>
                  <th>Núm. NCM</th>
                  <th>Preço de Venda</th>
                  <th>Custo de Reposição</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{`${novoProduto.des_unidade_compra} / ${novoProduto.des_unidade_venda}`}</td>
                  <td>{novoProduto.cod_ncm}</td>
                  <td>{novoProduto.num_ncm}</td>
                  <td>{moneyFormat(String(novoProduto.val_preco), 2)}</td>
                  <td>{moneyFormat(String(novoProduto.val_custo_rep), 2)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </Container>
  );
};
